import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { useMutation } from '@tanstack/react-query'
import { WNATIVE } from 'constants/token'
import { UseSwapProps } from 'hooks/tokenmill/useSwap'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { useFeeMode, useMaxTransactionFee } from 'state/settings/hooks'
import { TokenMillQuote } from 'types/quote'
import { getChainId } from 'utils/chains'
import { formattedNum } from 'utils/format'
import { buildTransactionWithPriorityFee } from 'utils/transaction'
import { formatUnits } from 'viem'

import useSimulateSwap, { getSwapInstructions } from './useSimulateSwap'

const useSwap = ({
  allowedSlippageBps,
  amountIn,
  chain,
  currencyIn,
  currencyOut,
  enabled,
  marketAddress,
  onSwapSuccess,
  referrerAddress
}: UseSwapProps) => {
  const { connection } = useConnection()
  const wallet = useWallet()
  const chainId = getChainId(chain)
  const addTransactionToast = useTransactionToast()
  const { maxTransactionFee } = useMaxTransactionFee()
  const { feeMode } = useFeeMode()

  const swapType: 'buy' | 'sell' =
    currencyIn?.address === WNATIVE[chainId].address ? 'buy' : 'sell'
  const baseTokenAddress =
    swapType === 'buy' ? currencyOut?.address : currencyIn?.address
  const quoteTokenAddress =
    swapType === 'buy' ? currencyIn?.address : currencyOut?.address

  const swapArgs = {
    amountIn,
    baseTokenAddress,
    marketAddress,
    quoteTokenAddress,
    swapType
  }

  const {
    data: { simulatedAmountOut } = {},
    error: simulateError,
    isLoading: isLoadingQuote
  } = useSimulateSwap({
    ...swapArgs,
    enabled
  })

  const quote = useMemo(() => {
    if (currencyIn && currencyOut && amountIn && simulatedAmountOut) {
      const amountOut = {
        formatted: formatUnits(simulatedAmountOut, currencyOut.decimals),
        value: simulatedAmountOut
      }

      const slippageAdjustedAmountOut =
        (amountOut.value * BigInt(Math.round(10000 - allowedSlippageBps))) /
        BigInt(10000)
      const minAmountOut = {
        formatted: formatUnits(slippageAdjustedAmountOut, currencyOut.decimals),
        value: slippageAdjustedAmountOut
      }

      return {
        amountIn: {
          formatted: formatUnits(amountIn, currencyIn.decimals),
          value: amountIn
        },
        amountOut,
        currencyIn,
        currencyOut,
        minAmountOut
      } satisfies TokenMillQuote
    }

    return undefined
  }, [
    currencyIn,
    currencyOut,
    amountIn,
    simulatedAmountOut,
    allowedSlippageBps
  ])

  const {
    isPending: isSwapping,
    mutateAsync: swapAsync,
    reset: resetSwap
  } = useMutation({
    mutationFn: async () => {
      if (!wallet.publicKey) throw new Error('Wallet not connected')
      if (!quote) throw new Error('Quote not available')

      const swapIxs = await getSwapInstructions({
        ...swapArgs,
        connection,
        minAmountOut: quote.minAmountOut.value,
        referrerAddress,
        walletPublicKey: wallet.publicKey
      })

      const { latestBlockhash, transaction } =
        await buildTransactionWithPriorityFee(
          connection,
          swapIxs,
          wallet.publicKey,
          maxTransactionFee,
          feeMode
        )

      const signature = await wallet.sendTransaction(transaction, connection)

      addTransactionToast({
        chain,
        description: `Swapped ${formattedNum(quote.amountIn.formatted)} ${
          quote.currencyIn.symbol
        } for ${formattedNum(quote.amountOut.formatted)} ${
          quote.currencyOut.symbol
        }`,
        hash: signature,
        walletAddress: wallet.publicKey.toBase58()
      })

      const result = await connection.confirmTransaction(
        {
          blockhash: latestBlockhash.blockhash,
          lastValidBlockHeight: latestBlockhash.lastValidBlockHeight,
          signature
        },
        'processed'
      )

      if (result.value.err) {
        throw new Error('Transaction failed')
      }

      return signature
    },
    onSuccess: onSwapSuccess
  })

  return {
    error: simulateError
      ? {
          message: simulateError.message,
          summary: simulateError.name
        }
      : undefined,
    isLoadingQuote,
    isSwapping,
    quote,
    resetSwap,
    swapAsync
  }
}

export default useSwap
