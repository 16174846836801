import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  LinkBox,
  LinkOverlay,
  Spinner,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import ConnectWalletPlaceholder from 'components/ConnectWalletPlaceholder'
import CurrencyLogo from 'components/CurrencyLogo'
import { format } from 'date-fns'
import useGetUserPortfolioEVM from 'hooks/barn/evm/useGetUserPortfolio'
import useGetUserStakingPositionsEVM from 'hooks/barn/evm/useGetUserStakingPositions'
import useGetUserVestingPositionsEVM from 'hooks/barn/evm/useGetUserVestingPositions'
import useGetUserStakingPositionsSolana from 'hooks/barn/solana/useGetUserStakingPositions'
import useGetUserVestingPositionsSolana from 'hooks/barn/solana/useGetUserVestingPositions'
import useAccountOnChain from 'hooks/tokenmill/useAccountOnChain'
import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import useUserOwnedTokensSolana from 'solana/hooks/useUserOwnedTokens'
import { formattedNum } from 'utils/format'

const Portfolio = () => {
  const { address: addressEVM } = useAccountOnChain('avalanche')
  const { address: addressSolana } = useAccountOnChain('solana')

  // get tokens
  const { data: userTokensSolana = [], isLoading: isLoadingUserTokensSolana } =
    useUserOwnedTokensSolana()
  const { data: userTokensEVM = [], isLoading: isLoadingUserTokensEVM } =
    useGetUserPortfolioEVM({
      chain: 'avalanche',
      userAddress: addressEVM
    })

  const tokens = useMemo(() => {
    return [...userTokensEVM, ...userTokensSolana].sort(
      (a, b) => b.valueUsd - a.valueUsd
    )
  }, [userTokensEVM, userTokensSolana])

  const isLoadingUserTokens =
    isLoadingUserTokensEVM || isLoadingUserTokensSolana

  const totalWalletValueUsd = tokens.reduce(
    (acc, token) => acc + token.valueUsd,
    0
  )

  // get staking positions on EVM
  const {
    data: userStakingPositionsEVM,
    fetchNextPage: fetchNextUserStakingPositionsEVM,
    hasNextPage: hasNextUserStakingPositionsEVM,
    isFetchingNextPage: isFetchingNextUserStakingPositionsEVM
  } = useGetUserStakingPositionsEVM({
    chain: 'avalanche',
    userAddress: addressEVM
  })

  // get staking positions on solana
  const {
    data: userStakedPositionsSolana,
    fetchNextPage: fetchNextUserStakedPositionsSolana,
    hasNextPage: hasNextUserStakedPositionsSolana,
    isFetchingNextPage: isFetchingNextUserStakedPositionsSolana
  } = useGetUserStakingPositionsSolana({
    userAddress: addressSolana
  })

  const userStakedPositions = useMemo(() => {
    const data = [
      ...(userStakedPositionsSolana?.pages.flatMap((page) => page) ?? []),
      ...(userStakingPositionsEVM?.pages.flatMap((page) => page) ?? [])
    ]
    return data.sort((a, b) => b.valueUsd - a.valueUsd)
  }, [userStakedPositionsSolana, userStakingPositionsEVM])

  const totalStakedValueUsd = userStakedPositions.reduce(
    (acc, token) => acc + token.valueUsd,
    0
  )

  // get vesting positions on EVM
  const {
    data: userVestingPositionsEVM,
    fetchNextPage: fetchNextUserVestingPositionsEVM,
    hasNextPage: hasNextUserVestingPositionsEVM,
    isFetchingNextPage: isFetchingNextUserVestingPositionsEVM
  } = useGetUserVestingPositionsEVM({
    chain: 'avalanche',
    userAddress: addressEVM
  })

  // get vesting positions on solana
  const {
    data: userVestingPositionsSolana,
    fetchNextPage: fetchNextUserVestingPositionsSolana,
    hasNextPage: hasNextUserVestingPositionsSolana,
    isFetchingNextPage: isFetchingNextUserVestingPositionsSolana
  } = useGetUserVestingPositionsSolana({
    userAddress: addressSolana
  })

  const userVestingPositions = useMemo(() => {
    const data = [
      ...(userVestingPositionsSolana?.pages.flatMap((page) => page) ?? []),
      ...(userVestingPositionsEVM?.pages.flatMap((page) => page) ?? [])
    ]
    return data.sort((a, b) => b.valueUsd - a.valueUsd)
  }, [userVestingPositionsSolana, userVestingPositionsEVM])

  const totalVestingValueUsd = userVestingPositions.reduce(
    (acc, token) => acc + token.valueUsd,
    0
  )

  const totalValueUsd =
    totalWalletValueUsd + totalStakedValueUsd + totalVestingValueUsd

  if (!addressEVM && !addressSolana) {
    return (
      <ConnectWalletPlaceholder
        title="Portfolio"
        description="Connect your wallet to view your portfolio"
        defaultChainType="evm"
      />
    )
  }

  return (
    <Box maxW="1600px" margin="0 auto" minH="90svh">
      <HStack p={{ base: 4, md: 6 }} justify="space-between">
        <Heading size="sm">Portfolio</Heading>
        <HStack>
          <Text fontSize="sm" textColor="textSecondary">
            Total Value USD:
          </Text>
          <Text fontWeight="semibold">
            {formattedNum(totalValueUsd, { usd: true })}
          </Text>
        </HStack>
      </HStack>

      <Divider />

      <Tabs variant="bracketed" p={{ base: 4, md: 6 }}>
        <TabList>
          <Tab>Wallet ({tokens.length})</Tab>
          <Tab>Staking ({userStakedPositions.length})</Tab>
          <Tab>Vesting ({userVestingPositions.length})</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <TableContainer w="full">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th pl={0}>Token</Th>
                    <Th textAlign="right">Price</Th>
                    <Th textAlign="right">Amount</Th>
                    <Th pr={0} textAlign="right">
                      USD Value
                    </Th>
                  </Tr>
                </Thead>

                {isLoadingUserTokens ? (
                  <Tbody pt={8}>
                    <Tr>
                      <Td colSpan={4} textAlign="center">
                        <Spinner />
                      </Td>
                    </Tr>
                  </Tbody>
                ) : (
                  <Tbody>
                    {tokens.map((token) => {
                      return (
                        <LinkBox
                          as={Tr}
                          key={token.tokenAddress + token.tokenSymbol}
                          transform="scale(1)"
                          cursor="pointer"
                          role="group"
                          _hover={{ bg: 'bgSecondary' }}
                        >
                          <Td pl={0}>
                            <LinkOverlay
                              as={NavLink}
                              to={`/${token.chain}/${token.marketAddress}`}
                            />
                            <HStack>
                              <CurrencyLogo
                                logoURI={token.tokenLogoURI}
                                boxSize={6}
                                address={token.tokenAddress}
                                symbol={token.tokenSymbol}
                                chain={token.chain}
                              />
                              <Text fontWeight="semibold">
                                {token.tokenSymbol}
                              </Text>
                            </HStack>
                          </Td>
                          <Td isNumeric>
                            {formattedNum(token.priceUsd, { usd: true })}
                          </Td>
                          <Td isNumeric>
                            {formattedNum(token.amount, { places: 5 })}
                          </Td>
                          <Td pr={0} isNumeric>
                            {formattedNum(token.valueUsd, { usd: true })}
                          </Td>
                        </LinkBox>
                      )
                    })}
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </TabPanel>

          <TabPanel p={0}>
            <Box>
              <TableContainer w="full">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th pl={0}>Token</Th>
                      <Th textAlign="right">Price</Th>
                      <Th textAlign="right">Amount</Th>
                      <Th pr={0} textAlign="right">
                        USD Value
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {userStakedPositions.map((position) => {
                      return (
                        <LinkBox
                          as={Tr}
                          key={position.marketAddress}
                          transform="scale(1)"
                          cursor="pointer"
                          role="group"
                          _hover={{ bg: 'bgSecondary' }}
                        >
                          <Td pl={0}>
                            <LinkOverlay
                              as={NavLink}
                              to={`/${position.chain}/${position.marketAddress}/stake`}
                            />
                            <HStack>
                              <CurrencyLogo
                                boxSize={6}
                                logoURI={position.baseTokenLogoUrl}
                                chain={position.chain}
                              />
                              <Text fontWeight="semibold">
                                {position.baseTokenSymbol}
                              </Text>
                            </HStack>
                          </Td>
                          <Td isNumeric>
                            {formattedNum(position.baseTokenPriceUsd, {
                              usd: true
                            })}
                          </Td>
                          <Td isNumeric>
                            {formattedNum(position.amountStaked, {
                              places: 5
                            })}
                          </Td>
                          <Td pr={0} isNumeric>
                            {formattedNum(position.valueUsd, { usd: true })}
                          </Td>
                        </LinkBox>
                      )
                    })}
                  </Tbody>
                </Table>

                {(hasNextUserStakedPositionsSolana ||
                  hasNextUserStakingPositionsEVM) && (
                  <Box mt={4} textAlign="center">
                    <Button
                      variant="boxShadowFlat"
                      onClick={() => {
                        if (hasNextUserStakedPositionsSolana) {
                          fetchNextUserStakedPositionsSolana()
                        }
                        if (hasNextUserStakingPositionsEVM) {
                          fetchNextUserStakingPositionsEVM()
                        }
                      }}
                      isLoading={
                        isFetchingNextUserStakedPositionsSolana ||
                        isFetchingNextUserStakingPositionsEVM
                      }
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </TableContainer>
            </Box>
          </TabPanel>

          <TabPanel p={0}>
            <Box>
              <TableContainer w="full">
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th pl={0}>Token</Th>
                      <Th>Vesting Schedule</Th>
                      <Th textAlign="right">Price</Th>
                      <Th textAlign="right">Progress</Th>
                      <Th pr={0} textAlign="right">
                        USD Value
                      </Th>
                    </Tr>
                  </Thead>

                  <Tbody>
                    {userVestingPositions.map((position) => {
                      return (
                        <LinkBox
                          as={Tr}
                          key={position.marketAddress}
                          transform="scale(1)"
                          cursor="pointer"
                          role="group"
                          _hover={{ bg: 'bgSecondary' }}
                        >
                          <Td pl={0}>
                            <LinkOverlay
                              as={NavLink}
                              to={`/${position.chain}/${position.marketAddress}/vesting`}
                            />
                            <HStack>
                              <CurrencyLogo
                                boxSize={6}
                                logoURI={position.baseTokenLogoUrl}
                                chain={position.chain}
                              />
                              <Text fontWeight="semibold">
                                {position.baseTokenSymbol}
                              </Text>
                            </HStack>
                          </Td>
                          <Td>
                            {format(
                              (position.start + position.cliffDuration) * 1000,
                              'MMM dd, yyyy, hh:mm a'
                            )}
                            {' - '}
                            {format(
                              (position.start +
                                position.cliffDuration +
                                position.vestingDuration) *
                                1000,
                              'MMM dd, yyyy, hh:mm a'
                            )}
                          </Td>
                          <Td isNumeric>
                            {formattedNum(position.baseTokenPriceUsd, {
                              usd: true
                            })}
                          </Td>
                          <Td isNumeric>
                            {`${formattedNum(position.amountReleased, {
                              places: 5
                            })} / ${formattedNum(position.vestingAmount, {
                              places: 5
                            })}`}
                          </Td>
                          <Td pr={0} isNumeric>
                            {formattedNum(position.valueUsd, { usd: true })}
                          </Td>
                        </LinkBox>
                      )
                    })}
                  </Tbody>
                </Table>

                {(hasNextUserVestingPositionsSolana ||
                  hasNextUserVestingPositionsEVM) && (
                  <Box mt={4} textAlign="center">
                    <Button
                      variant="boxShadowFlat"
                      onClick={() => {
                        if (hasNextUserVestingPositionsSolana) {
                          fetchNextUserVestingPositionsSolana()
                        }
                        if (hasNextUserVestingPositionsEVM) {
                          fetchNextUserVestingPositionsEVM()
                        }
                      }}
                      isLoading={
                        isFetchingNextUserVestingPositionsSolana ||
                        isFetchingNextUserVestingPositionsEVM
                      }
                    >
                      Load More
                    </Button>
                  </Box>
                )}
              </TableContainer>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default Portfolio
