import { useInfiniteQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, DexbarnUserVestingPosition } from 'types/dexbarn'
import { PortfolioVestingPosition } from 'types/portfolio'

export interface UseGetUserVestingPositionsProps {
  chain: Chain
  pageSize?: number
  userAddress?: string
}

const useGetUserVestingPositions = ({
  chain,
  pageSize = 20,
  userAddress
}: UseGetUserVestingPositionsProps) => {
  const fetchUserVestingPositions = useDexbarnGet<DexbarnUserVestingPosition[]>(
    `/v1/vesting/${chain}/user/${userAddress}`
  )

  return useInfiniteQuery<PortfolioVestingPosition[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserVestingPositions({
        params: {
          page_num: pageParam,
          page_size: pageSize,
          user_id: userAddress
        }
      })

      return data.map(
        (position): PortfolioVestingPosition => ({
          amountReleased: Number(position.amountReleased),
          baseTokenLogoUrl: position.iconUrl || '',
          baseTokenPriceUsd: position.baseToken.priceUsd,
          baseTokenSymbol: position.baseToken.symbol,
          chain: 'avalanche',
          cliffDuration: position.cliffDuration,
          marketAddress: position.marketAddress,
          start: new Date(position.createdAt).getTime() / 1000,
          valueUsd:
            Number(position.vestingAmount) * position.baseToken.priceUsd,
          vestingAmount: Number(position.vestingAmount),
          vestingDuration: position.vestingDuration
        })
      )
    },
    queryKey: ['GetUserVestingPositions', pageSize, userAddress]
  })
}

export default useGetUserVestingPositions
