import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { TOKEN_2022_PROGRAM_ID } from '@solana/spl-token'
import { getMint, getTokenMetadata } from '@solana/spl-token'
import { Connection, PublicKey } from '@solana/web3.js'

export const getTokenProgramID = async (
  tokenAddress: string,
  connection: Connection
) => {
  try {
    const mint = new PublicKey(tokenAddress)
    await getMint(connection, mint, 'confirmed', TOKEN_2022_PROGRAM_ID)
    return TOKEN_2022_PROGRAM_ID
  } catch {
    return TOKEN_PROGRAM_ID
  }
}

export const getTokenMetadataWithFallback = async (
  tokenAddress: string,
  connection: Connection
) => {
  try {
    return await getTokenMetadata(
      connection,
      new PublicKey(tokenAddress),
      'confirmed',
      TOKEN_PROGRAM_ID
    )
  } catch {
    return await getTokenMetadata(
      connection,
      new PublicKey(tokenAddress),
      'confirmed',
      TOKEN_2022_PROGRAM_ID
    )
  }
}

export function deriveMarketAddressFromBaseToken(
  baseTokenMint: PublicKey
): PublicKey | undefined {
  try {
    const [marketPda] = PublicKey.findProgramAddressSync(
      [Buffer.from('market'), baseTokenMint.toBuffer()],
      new PublicKey('JoeaRXgtME3jAoz5WuFXGEndfv4NPH9nBxsLq44hk9J')
    )

    return marketPda
  } catch (error) {
    console.error('Error deriving market address', error)
    return undefined
  }
}
