import {
  Button,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'

interface HowItWorksModalProps {
  isOpen: boolean
  onClose: () => void
}

const HowItWorksModal = ({ isOpen, onClose }: HowItWorksModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>How Token Mill Works</ModalHeader>
        <ModalCloseButton />
        <ModalBody pt={2}>
          <VStack align="stretch" spacing={4}>
            <Text>
              Token Mill is a super easy token launcher where fees go to YOU.
            </Text>

            <OrderedList spacing={3} pl={4}>
              <ListItem>Pick a coin that you like</ListItem>
              <ListItem>Buy the coin on the bonding curve</ListItem>
              <ListItem>Stake your coins to earn trading fees</ListItem>
              <ListItem>Sell at any time to lock in profits</ListItem>
              <ListItem>
                At $50k market cap, coin is eligible for King of the Mill
              </ListItem>
              <ListItem>
                When it reaches $50k market cap it receives the graduation
                milestone
              </ListItem>
              <ListItem>Refer your friends to earn even more fees</ListItem>
            </OrderedList>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button variant="boxShadowFlat" onClick={onClose}>
            OK
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default HowItWorksModal
