import { TokenMillFactoryAbi } from 'constants/abi/tokenMillFactory'
import { TM_FACTORY_ADDRESS } from 'constants/addresses'
import { TokenMillChainId } from 'constants/chains'
import { getAddress, isAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UseGetMarketOfProps {
  chainId: TokenMillChainId
  enabled?: boolean
  tokenAddress?: string
}

const useGetMarketOf = ({
  chainId,
  enabled,
  tokenAddress
}: UseGetMarketOfProps) => {
  return useReadContract({
    abi: TokenMillFactoryAbi,
    address: TM_FACTORY_ADDRESS[chainId],
    args:
      tokenAddress && isAddress(tokenAddress)
        ? [getAddress(tokenAddress)]
        : undefined,
    chainId,
    functionName: 'getMarketOf',
    query: {
      enabled: enabled && !!tokenAddress
    }
  })
}

export default useGetMarketOf
