import { useInfiniteQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, DexbarnUserStakingPosition } from 'types/dexbarn'
import { PortfolioStakingPosition } from 'types/portfolio'

export interface UseGetUserStakingPositionsProps {
  chain: Chain
  pageSize?: number
  userAddress?: string
}

const useGetUserStakingPositions = ({
  chain,
  pageSize = 20,
  userAddress
}: UseGetUserStakingPositionsProps) => {
  const fetchUserStakingPositions = useDexbarnGet<DexbarnUserStakingPosition[]>(
    `/v1/staking/${chain}/user/${userAddress}`
  )

  return useInfiniteQuery<PortfolioStakingPosition[]>({
    enabled: !!userAddress,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.length === pageSize) {
        return allPages.length + 1
      }
      return undefined
    },
    initialPageParam: 1,
    queryFn: async ({ pageParam = 1 }) => {
      const data = await fetchUserStakingPositions({
        params: {
          page_num: pageParam,
          page_size: pageSize,
          user_id: userAddress
        }
      })

      return data
        .map(
          (position): PortfolioStakingPosition => ({
            amountStaked: Number(position.amountStaked),
            baseTokenLogoUrl: position.iconUrl || '',
            baseTokenPriceUsd: position.baseToken.priceUsd,
            baseTokenSymbol: position.baseToken.symbol,
            chain: 'avalanche',
            marketAddress: position.marketAddress,
            valueUsd:
              Number(position.amountStaked) * position.baseToken.priceUsd
          })
        )
        .filter((position) => position.amountStaked > 0)
    },
    queryKey: ['GetUserStakingPositions', pageSize, userAddress]
  })
}

export default useGetUserStakingPositions
