import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Heading,
  HStack,
  Link,
  LinkBox,
  LinkOverlay,
  ListItem,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  UnorderedList,
  VStack
} from '@chakra-ui/react'
import CurrencyLogo from 'components/CurrencyLogo'
import useGetCrownRushLeaderboard from 'hooks/barn/solana/useGetCrownRushLeaderboard'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { formattedNum } from 'utils/format'

const CrownRush = () => {
  const fromTimestamp = 1737028800
  const toTimestamp = 1739707200

  const { data: crownRushData = [], isLoading: isLoadingCrownRushData } =
    useGetCrownRushLeaderboard({
      fromTimestamp,
      toTimestamp
    })

  const formatTimeHeld = (seconds: number) => {
    const days = Math.floor(seconds / (24 * 60 * 60))
    const hours = Math.floor((seconds % (24 * 60 * 60)) / (60 * 60))
    const minutes = Math.floor((seconds % (60 * 60)) / 60)

    const parts = []
    if (days > 0) parts.push(`${days}d`)
    if (hours > 0) parts.push(`${hours}h`)
    if (minutes > 0) parts.push(`${minutes}m`)

    return parts.join(' ')
  }

  return (
    <VStack
      maxW="800px"
      margin="0 auto"
      py={8}
      minH="80vh"
      px={{ base: 4, md: 0 }}
    >
      <VStack align="flex-start" w="full">
        <Heading size="md">Crown Rush</Heading>

        <Text fontSize="sm">
          Crown Rush is a month-long competition where tokens battle to hold
          King of The Mill for the longest duration. Ends February 16th, 12pm
          UTC. Top 3 share $25,000. Only on Solana.
        </Text>

        {isLoadingCrownRushData ? (
          <Center w="100%" py={8}>
            <Spinner />
          </Center>
        ) : (
          <TableContainer width="100%" mt={4}>
            <Table variant="simple" bg="bgSecondary">
              <Thead>
                <Tr>
                  <Th py={4}>Rank</Th>
                  <Th py={4}>Token</Th>
                  <Th py={4}>Market Cap</Th>
                  <Th py={4} isNumeric>
                    Market Cap at snapshot
                  </Th>
                  <Th py={4} isNumeric>
                    Time Held
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {crownRushData.map((item, i) => (
                  <LinkBox
                    as={Tr}
                    key={item.market_address}
                    _hover={{ bg: 'hover' }}
                    transform="scale(1)"
                    cursor="pointer"
                    role="group"
                  >
                    <Td>#{i + 1}</Td>
                    <Td>
                      <LinkOverlay
                        as={RouterLink}
                        to={`/solana/${item.market_address}`}
                      >
                        <HStack>
                          <CurrencyLogo logoURI={item.icon_url} boxSize={7} />
                          <Text>{item.base_token.symbol}</Text>
                          <Text textColor="textSecondary">
                            {item.base_token.name}
                          </Text>
                        </HStack>
                      </LinkOverlay>
                    </Td>
                    <Td isNumeric>
                      {formattedNum(item.base_market_cap_usd, { usd: true })}
                    </Td>
                    <Td isNumeric>
                      {formattedNum(item.snapshot_market_cap_usd, {
                        usd: true
                      })}
                    </Td>
                    <Td isNumeric>{formatTimeHeld(item.duration)}</Td>
                  </LinkBox>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        )}

        <Box width="100%" mt={8}>
          <Accordion allowMultiple width="100%" fontSize="sm">
            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  What is Crown Rush?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                Crown Rush is an incentive program for Token Mill&apos;s King of
                The Mill (KOTM) feature. The goal is to launch a token, claim
                the throne, and hold it as long as possible to win a share of
                rewards.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Campaign Details
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack align="stretch" spacing={2}>
                  <Text>Start: 16th Jan, 12pm UTC</Text>
                  <Text>End: 16th Feb, 12pm UTC</Text>
                  <Text>
                    Goal: Hold the KOTM title for the longest time during the
                    campaign period.
                  </Text>
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  What is King of The Mill (KOTM)?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack align="stretch" spacing={4}>
                  <Text>
                    KOTM is a competition where new tokens battle for the
                    throne.
                  </Text>

                  <VStack align="stretch" spacing={2}>
                    <Text fontWeight="semibold">How to Become King:</Text>
                    <Text>
                      Snapshots are taken every 30 minutes. Tokens must be less
                      than 48 hours old and have the highest market cap within
                      that age range.
                    </Text>
                  </VStack>

                  <VStack align="stretch" spacing={2}>
                    <Text fontWeight="semibold">Eligibility:</Text>
                    <Text>
                      Tokens must have a market cap of $10K or higher to claim
                      the crown.
                    </Text>
                  </VStack>
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Rules to Claim the Throne
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <UnorderedList spacing={2}>
                  <ListItem>
                    The current King holds the throne until a new token
                    surpasses its market cap.
                  </ListItem>
                  <ListItem>
                    Only tokens under 48 hours old and with a market cap above
                    $10K can compete.
                  </ListItem>
                  <ListItem>
                    Dethroned tokens under 48 hours old can reclaim the crown by
                    exceeding the current King&apos;s market cap.
                  </ListItem>
                  <ListItem>
                    The throne remains occupied until a new eligible token takes
                    over.
                  </ListItem>
                </UnorderedList>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Rewards (Total: $25,000)
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack align="stretch" spacing={4}>
                  <Text>
                    The top 3 tokens with the longest KOTM hold time will share
                    the prize pool:
                  </Text>

                  <VStack align="stretch" spacing={2}>
                    <Text>🥇 1st: $12,500</Text>
                    <Text>🥈 2nd: $7,500</Text>
                    <Text>🥉 3rd: $5,000</Text>
                  </VStack>

                  <VStack align="stretch" spacing={2}>
                    <Text fontWeight="semibold">
                      What happens if there&apos;s a tie?
                    </Text>
                    <Text>
                      If two tokens hold the crown for the same duration, the
                      token with the highest market cap at the end of the
                      campaign wins.
                    </Text>
                  </VStack>
                </VStack>
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionButton>
                <Box flex="1" textAlign="left" fontWeight="bold">
                  Have More Questions?
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel pb={4}>
                <VStack align="stretch" spacing={2}>
                  <Text>Join the community for support and updates:</Text>
                  <Link
                    href="https://t.me/tokenmilltards"
                    isExternal
                    color="accent.500"
                  >
                    Telegram
                  </Link>
                  <Link
                    href="https://discord.gg/nsS9Th7qTy"
                    isExternal
                    color="accent.500"
                  >
                    Discord
                  </Link>
                </VStack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </VStack>
    </VStack>
  )
}

export default CrownRush
